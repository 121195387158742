<template>
  <v-row>
    <!-- auctionvacations.com sitemap -->
    <v-col cols="8" class="d-none">
      <h3 class="mb-3">Generate Auction Vacations Sitemap</h3>
      <p>
        This function will fetch all available packages and generate a complete
        json sitemap for the
        <strong>
          <a href="https://auctionvacations.org" target="_blank"
            >Auction Vacations Partner Website.</a
          >
        </strong>
      </p>
      <ol>
        <li>Update <i>partners</i> array in <i>genAvSitemap</i> function</li>
        <li>Click <strong>Generate</strong> button to generate sitemap</li>
        <li>
          Copy and paste generated sitemap into
          <i>vue.config.js (urls) </i>in the Auction Vacations github repo
        </li>
        <li>
          After the code changes are pushed, the github actions will
          automatically rebuild and post the new website to AWS
        </li>
        <li>
          <a
            href="https://www.npmjs.com/package/vue-cli-plugin-sitemap#usage-as-a-standalone-plugin"
            target="_blank"
            >vue-cli-plugin-sitemap / Usage as a standalone plugin
          </a>
        </li>
      </ol>

      <v-btn @click="genAvSitemap" color="primary" class="my-5" depressed
        >Generate AV Sitemap</v-btn
      >
      <pre>{{ avSitemap.length }} sitemap urls</pre>
      <pre>{{ avSitemap }}</pre>
    </v-col>

    <!-- rebuildingSoon -->
    <v-col cols="4" class="d-none">
      <v-switch
        inset
        v-model="rebuildingSoon.value"
        :label="`Portal Rebuilding Soon: ${rebuildingSoon.value}`"
        @change="setRebuildingSoon"
      ></v-switch>
    </v-col>

    <!-- divider -->
    <v-col cols="12" class="d-none">
      <hr class="mt-5" />
    </v-col>

    <!-- tempData -->
    <v-col cols="12">
      <v-btn @click="temp" color="error" depressed class="mb-12 mr-6"
        >temp</v-btn
      >
      <div>
        <code>{{ tempData }}</code>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { portalsApp, mainApp } from "@/firebase";
import formatDate from "@/mixins/formatDate";
import moment from "moment";

export default {
  mixins: [formatDate],
  async mounted() {
    this.$bind(
      "rebuildingSoon",
      portalsApp
        .firestore()
        .collection("admin")
        .doc("rebuildingSoon")
    ).then((data) => {
      this.rebuildingSoon = data;
    });
  },
  data: () => ({
    rebuildingSoon: {
      value: false,
    },
    avSitemap: [],
    tempData: [],
  }),
  methods: {
    async temp() {
      return;
      portalsApp
        .firestore()
        .collection("inventoryProperties")
        .doc("BDRC")
        .get()
        .then((doc) => {
          let data = doc.data();
          portalsApp
            .firestore()
            .collection("inventoryProperties")
            .doc("7865")
            .set(data, { merge: true })
            .then(() => console.log("done"));
        });

      return;
      // adds new basic property information to db for a hotels.com property
      const newHotelsComProp = async (oldId, newId) => {
        let docToClone = await portalsApp
          .firestore()
          .collection("inventoryProperties")
          .doc(oldId)
          .get();
        if (!docToClone.exists)
          return console.log(oldId, "does not exist in db");
        docToClone = docToClone.data();

        // changing new prop vals, but keeping base doc structure
        docToClone.id = "";
        docToClone.overview = "";
        docToClone.amenities =
          '<h3 class="info-header">Resort Amenities</h3><div class="info-item"><p></p></div>';
        docToClone.checkInInfo =
          '<h3 class="info-header">Check-In Information</h3><div class="info-item"><p>Check-in time 3 PM-midnight</p><p>Check-out time is noon</p></div>';
        docToClone.airportInfo =
          '<h3 class="info-header">Airport Location</h3><div class="info-item"><p></p></div>';
        docToClone.otherInfo =
          '<h3 class="info-header">Other Information</h3><div class="info-item"><p></p></div>';
        docToClone["image-src"] = "";
        docToClone.resortPropertyId = "";
        docToClone.destination = "";
        docToClone.activities =
          '<h3 class="info-header">Activities</h3><div class="info-item"><p></p></div>';
        docToClone.dateUpdated = "";
        docToClone.resortAddress = "";
        docToClone.resortName = "";
        docToClone.dateSubmitted = new Date().toISOString().substring(0, 10);
        docToClone.resortPropertyId = newId;
        docToClone.restaurants =
          '<h3 class="info-header">Restaurants and Dining</h3><div class="info-item"><p></p></div>';

        // checks to make sure newId doesn't exist
        let newIdAlreadyExists = await portalsApp
          .firestore()
          .collection("inventoryProperties")
          .doc(newId)
          .get();
        if (newIdAlreadyExists.exists)
          return console.log(newId, "already exists in db");

        // newId doesn't already exists
        // add new inv prop
        await portalsApp
          .firestore()
          .collection("inventoryProperties")
          .doc(newId)
          .set(docToClone);

        console.log("added invProp ", newId);
      };

      await newHotelsComProp("7655", "7865");

      return;
      // adds boilerplate info for a hotels.com inv listings
      const newInvListingBoilerplate = async (newId, source) => {
        let newDoc = await portalsApp
          .firestore()
          .collection("inventoryListings")
          .doc(newId)
          .get();

        if (newDoc.exists) return console.log(newId, "already exists in db");

        // add new doc
        await portalsApp
          .firestore()
          .collection("inventoryListings")
          .doc(newId)
          .set({
            dateSubmitted: new Date().toISOString().substring(0, 10),
            destination: "",
            items: [],
            resortName: "",
            source: source,
            template: "inventoryListings",
          });

        console.log("added", newId, "to inventoryListings");
      };

      await newInvListingBoilerplate("BDRC2", "tripbeat");

      return;

      return;
      let props = [
        "HCGF",
        "HCGP",
        "HCAH",
        "HCVM",
        "HCEI",
        "HCHM",
        "HCHP",
        "HCNH",
        "HCPM",
        "HCLE",
        "HCMG",
        "HCGT",
        "TJOE",
      ];

      for (let idx = 0; idx < props.length; idx++) {
        let counter = 0;
        const el = props[idx];
        counter = 0;

        let invPropDoc = await portalsApp
          .firestore()
          .collection("inventoryListings")
          .doc(el)
          .get();

        if (invPropDoc.exists) {
          let invData = invPropDoc.data();
          let listingItems = invData.items;
          if (listingItems.length) {
            for (let index = 0; index < listingItems.length; index++) {
              const element = listingItems[index];
              counter++;
              element.numberOfNights = 5;
              element.checkOutDate = moment(element.checkInDate)
                .add(5, "days")
                .toISOString()
                .substring(0, 10);

              if (counter == listingItems.length) {
                await portalsApp
                  .firestore()
                  .collection("inventoryListings")
                  .doc(el)
                  .update({ items: listingItems });
                console.log("done", counter, listingItems.length);
                counter = 0;
              }
            }
          }
        }
      }

      return;

      for (let idx = 0; idx < props.length; idx++) {
        const el = props[idx];
        portalsApp
          .firestore()
          .collection("inventoryListings")
          .doc(el)
          .get()
          .then((doc) => {
            if (doc.exists) {
              let data = doc.data();
              let items = data.items;
              let newItems = items;

              if (newItems.length) {
                for (let index = 0; index < newItems.length; index++) {
                  counter++;
                  const item = newItems[index];
                  item.numberOfNights = 5;
                  console.log("item updated", el, item.id);
                  if (counter == newItems.length) {
                    portalsApp
                      .firestore()
                      .collection("inventoryListings")
                      .doc(el)
                      .update({ items: newItems })
                      .then(() => {
                        console.log("done", el);
                        counter = 0;
                      });
                  }
                }
              } else {
                counter = 0;
              }
            } else {
              console.log("DOES NOT EXIST", el);
              counter = 0;
            }
          });
      }

      return;

      // adds new tripbeat/all resorts store inventory property
      async function newTbProp(portalsApp, newId, oldId, collection) {
        // get existing inventoryProp
        let docToClone = await portalsApp
          .firestore()
          .collection(collection)
          .doc(oldId)
          .get();
        docToClone = docToClone.data();

        // modify values
        docToClone.airportInfo = "";
        docToClone.amenities = `<div class="list_wrap_amenities"> <div id="amenities_1"> <h5>On-Site</h5> <ul> <li>·</li></ul> </div><div id="amenities_2"> <h5>Nearby</h5> <ul> <li>·</li></ul> </div></div><div style="clear: both;"></div>`;
        docToClone.checkInInfo = `<h5>Check-In Days</h5><small></small>`;
        docToClone.dateSubmitted = new Date().toISOString().substring(0, 10);
        docToClone.dateUpdated = "";
        docToClone.destination = "";
        docToClone.id = "";
        docToClone["image-src"] = "";
        docToClone.overview = "";
        docToClone.resortAddress = "";
        docToClone.resortName = "";
        docToClone.resortPropertyId = newId;
        docToClone.restrictions =
          "No smoking in units: could result in forfeiture of the unit and/or other penalties. Pet Information: No Pets. Please contact the resort directly regarding its ADA/general service animal policy.";

        // add new prop
        await portalsApp
          .firestore()
          .collection(collection)
          .doc(newId)
          .set(docToClone);

        console.log("added invProp ", newId);

        //
      }

      // adds new tripbeat/all resorts store inventory listing
      async function newTbInvListing(portalsApp, newId, oldId, collection) {
        // get existing inventory listing
        let docToClone = await portalsApp
          .firestore()
          .collection(collection)
          .doc(oldId)
          .get();
        docToClone = docToClone.data();

        docToClone.dateSubmitted = new Date().toISOString().substring(0, 10);
        docToClone.destination = "";
        docToClone.id = "";
        docToClone.items = [];
        docToClone.resortName = "";

        console.log("docToClone ", docToClone);

        // adding new doc
        await portalsApp
          .firestore()
          .collection(collection)
          .doc(newId)
          .set(docToClone);

        console.log("added invListing ", newId);

        //
      }

      return;
      // find invoice containing puid
      let puidToFind = "123-052521-3951";

      portalsApp
        .firestore()
        .collection("eventReports")
        .get()
        .then((snap) => {
          snap = snap.docs.map((i) => i.data());
          snap.forEach((i) => {
            if (i.winnerPackageIDs.some((i) => i == puidToFind)) {
              console.log("found", i.id);
            }
          });
        });

      return;

      // await newTbProp(portalsApp, "6367", "WPN", "inventoryProperties");
      // await newTbInvListing(portalsApp, "6367", "WPN", "inventoryListings");

      return;
      portalsApp
        .firestore()
        .collection("userAccounts")
        .get()
        .then((snap) => {
          let docs = snap.docs.map((i) => i.data());
          docs = docs.map((i) => i.dateRegistered);
          let array = [];
          docs.forEach((i) => {
            let split = i.split("-");

            let year = split[0];
            let month = split[1];
            let day = split[2];

            year = parseInt(year);
            month = parseInt(month);
            day = parseInt(day);

            if (year === 2022 && month === 10) {
              if (day < 3) {
                console.log(year, month, day);
                array.push(i);
              }
            }
          });

          console.log("array", array.length);
        });

      return;

      //
      // let kbch = await portalsApp
      //   .firestore()
      //   .collection("inventoryListings")
      //   .doc("KBCH")
      //   .get();

      // kbch = kbch.data();

      // let items = kbch.items;

      // items.forEach((i) => {
      //   i.kitchen = "full";
      //   i.dateUpdated = new Date().toISOString().substring(0, 10);
      //   console.log("item", i.kitchen, i.dateUpdated);
      // });

      // await portalsApp
      //   .firestore()
      //   .collection("inventoryListings")
      //   .doc("KBCH")
      //   .update({ items: items });

      return;

      mainApp
        .firestore()
        .collection("packages")
        .where("available", "==", true)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            let data = doc.data();
            let string = data.youtubeVideoUrl;
            let substring = "embed";
            if (!string.includes(substring)) console.log(data.packageCode);
          });
        });

      return;
      // portalsApp
      //   .firestore()
      //   .collection("eventReports")
      //   .doc("NaN-1-20220806-195626")
      //   .get()
      //   .then((doc) => {
      //     let data = doc.data();
      //     data.eventReportNumber = "856-1-20220806-195626";
      //     portalsApp
      //       .firestore()
      //       .collection("eventReports")
      //       .doc("856-1-20220806-195626").
      //     set(data);
      //   });

      return;
      let userAccounts = await portalsApp
        .firestore()
        .collection("userAccounts")
        .get();

      userAccounts = userAccounts.docs.map((i) => i.data());

      let eventReports = await portalsApp
        .firestore()
        .collection("eventReports")
        .where("archived", "==", false)
        .get();

      eventReports = eventReports.docs.map((i) => i.data());
      eventReports = eventReports.filter((i) => i.eventReportNumber != 660);

      let winners = [];
      eventReports.forEach((i) => {
        i.winners.forEach((w) => {
          w.eventReportNumber = i.eventReportNumber;
          w.isConfirmed = false;
          winners.push(w);
        });
      });

      // winners = winners.filter((i) => i.isRegistered);

      let count = 0;
      winners.forEach((i) => {
        if (userAccounts.some((u) => u.packageID === i.packageID)) {
          let userAccount = userAccounts.find(
            (u) => u.packageID === i.packageID
          );
          i.isConfirmed = userAccount.isConfirmed;
          count = count + 1;
        }
      });

      let array = [];
      winners.forEach((i) => {
        let startDate = "2021-12-31";
        let endDate = "2022-06-30";
        let travelByDate = moment(i.travelByDate, "YYYY-MM-DD");
        let isBetween = moment(travelByDate).isBetween(startDate, endDate);
        if (isBetween) array.push(i);
      });

      // this.tempData = array;
      console.log(array.length);
      return;

      return;
      portalsApp
        .firestore()
        .collection("eventReports")
        .where("archived", "==", false)
        .get()
        .then((snap) => {
          console.log(snap.docs.length);
          let array = [];
          snap.docs.forEach((doc) => {
            if (doc.data().eventReportNumber != 660) {
              let eventReportNumber = doc.data().eventReportNumber;

              array.push(doc.data());
            }
          });
          console.log(array.length);
        });

      return;
      // creates new inventory property and inventory listing for a tripbeat inventory property
      const oldDoc = "C178";
      const newDoc = "3031";

      // gets inventory property to copy
      let invPropToCopy = await portalsApp
        .firestore()
        .collection("inventoryProperties")
        .doc(oldDoc)
        .get();

      // set new invProp data
      let newInvProp = invPropToCopy.data();
      newInvProp.dateSubmitted = new Date().toISOString().substring(0, 10);
      newInvProp.dateUpdated = "";
      newInvProp.destination = "";
      newInvProp.id = newDoc;
      newInvProp["image-src"] = "";
      newInvProp.overview = "";
      newInvProp.resortAddress = "";
      newInvProp.resortName = "";
      newInvProp.resortPropertyId = newDoc;
      newInvProp.unitInfo = "";
      newInvProp.activities = `<h3 class="info-header">Activities</h3><div class="info-item"><p></p></div>`;
      newInvProp.airportInfo = `<h3 class="info-header">Airport Location</h3><div class="info-item"><p></p></div>`;
      newInvProp.amenities = `<h3 class="info-header">Resort Amenities</h3><div class="info-item"><p></p></div>`;
      newInvProp.checkInInfo = `<h3 class="info-header">Check-In Information</h3><div class="info-item"><p></p></div>`;
      newInvProp.otherInfo = `<h3 class="info-header">Other Information</h3><div class="info-item"><p>In preparation for your upcoming vacation please check for any testing requirements, travel restrictions, or local mandates related to Covid-19 to and from your confirmed destination. Resources available online include, but are not limited to, government, airline, and your host resort's website.</p></div>`;
      newInvProp.restaurants = `<h3 class="info-header">Restaurants and Dining</h3><div class="info-item"></div>`;
      newInvProp.restrictions = `<h3 class="info-header">Policy Restrictions</h3><div class="info-item"><p></p></div>`;

      // creates new firestore invProp
      await portalsApp
        .firestore()
        .collection("inventoryProperties")
        .doc(newDoc)
        .set(newInvProp);

      // gets inventory listing to copy
      let invListingToCopy = await portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(oldDoc)
        .get();

      // set new inventory listing data
      let newInvListing = invListingToCopy.data();
      newInvListing.dateSubmitted = new Date().toISOString().substring(0, 10);
      newInvListing.destination = "";
      newInvListing.id = newDoc;
      newInvListing.items = [];
      newInvListing.resortName = "";

      // creates new firestore invListing
      await portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(newDoc)
        .set(newInvListing);

      console.log(
        "both created. don't forget to add a new webscraper if needed"
      );
    },
    setRebuildingSoon() {
      portalsApp
        .firestore()
        .collection("admin")
        .doc("rebuildingSoon")
        .update({
          usersReady: false,
          value: this.rebuildingSoon.value,
        });
    },
    genAvSitemap() {
      let baseUrl = "https://auctionvacations.org/";
      let partners = [
        "av",
        "alpert",
        "ktba",
        "tt",
        "ba",
        "baf",
        "cbu",
        "gb",
        "gas",
        "kr",
        "sk",
        "cw",
      ];
      // first item in pages array is blank to represent a home page ("/")
      let pages = [
        "",
        "experiences",
        "new",
        "american",
        "classic",
        "about",
        "contact",
        "all-inclusive",
        "knightsbrook",
        "golf",
        "cruises",
        "donations-for-vacations",
        "how-it-works",
        "package-guide",
        "best-sellers",
        "castlemartyr",
        "europe-ireland",
        "family",
        "exotic",
        "traveler-faqs",
        "destinations/alaska",
        "destinations/arizona",
        "destinations/california",
        "destinations/colorado",
        "destinations/hawaii",
        "destinations/illinois",
        "destinations/kansas",
        "destinations/louisiana",
        "destinations/massachusetts",
        "destinations/missouri",
        "destinations/montana",
        "destinations/nevada",
        "destinations/new%20york",
        "destinations/north%20carolina",
        "destinations/pennsylvania",
        "destinations/tennessee",
        "destinations/texas",
        "destinations/virginia",
        "destinations/washington%20d.c.",
        "destinations/alberta",
        "destinations/british%20columbia",
        "destinations/bahamas",
        "destinations/jamaica",
        "destinations/dominican%20republic",
        "destinations/bermuda",
        "destinations/austria",
        "destinations/czech%20republic",
        "destinations/england",
        "destinations/france",
        "destinations/greece",
        "destinations/ireland",
        "destinations/italy",
        "destinations/spain",
        "destinations/cancun",
        "destinations/cozumel",
        "destinations/los%20cabos",
        "destinations/puerto%20vallarta",
        "destinations/riviera%20maya",
        "destinations/indonesia",
        "destinations/india",
        "destinations/argentina",
        "destinations/gold%20coast",
      ];
      // get available packages
      portalsApp
        .firestore()
        .collection("retailPackages")
        .where("available", "==", true)
        .get()
        .then((snap) => {
          let packageCodes = snap.docs.map((i) => i.data().packageCode);
          partners.forEach((partner) => {
            // build package pages
            packageCodes.forEach((packageCode) => {
              let item = baseUrl + partner + "/p/" + packageCode;
              this.avSitemap.push(item);
            });
            // build pages (contact, about, etc.)
            pages.forEach((page) => {
              let item = baseUrl + partner + "/" + page;
              this.avSitemap.push(item);
            });
          });
        });
    },
  },
};
</script>
